<template>
  <vs-table class="transcation-list" :data="transactions">
    <template class="tableHeader" slot="thead">
      <vs-th> Business name </vs-th>
      <vs-th> Last updated </vs-th>
      <vs-th> Source</vs-th>
      <vs-th> ID</vs-th>
      <vs-th> Status </vs-th>
      <vs-th> Action</vs-th>
    </template>

    <template aria-valuemax="" slot-scope="{ data }">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="data[indextr].businessInfo.tradingName">
          {{ data[indextr].businessInfo.tradingName }}
        </vs-td>

        <vs-td :data="data[indextr].updatedAt">
          {{ moment(data[indextr].updatedAt) }}
        </vs-td>

        <vs-td :data="data[indextr].updatedAt"> waiting from introducer </vs-td>

        <vs-td :data="data[indextr].updatedAt">
          waiting from introducer id
        </vs-td>

        <vs-td :data="data[indextr].status">
          {{ data[indextr].status }}
        </vs-td>

        <vs-td :data="tr">
          <router-link
            :to="{
              name: 'mvpReview',
              params: { onboardingId: tr._id },
            }"
            style="text-decoration: underline; cursor: pointer"
            >View</router-link
          >
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "index",
  data() {
    return {
      transactions: [],
    };
  },
  mounted() {
    this.getOboardingList();
  },
  methods: {
    ...mapActions("onboardMerchant", ["fetchOnboardingList"]),

    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },

    async getOboardingList(page, limit) {
      this.$vs.loading();
      await this.fetchOnboardingList()
        .then((result) => {
          this.transactions = result.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "bottom-right",
          });
        });
    },
  },
};
</script>
